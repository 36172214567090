import React from "react";
import Button from "../button/button";
import { useTranslation } from "react-i18next";
import complex from "../../assets/images/complex.png";
import { BannerLeft, BannerRight, BtnWrap, Wrapper } from "./style";

interface BannerProps {}

const Banner: React.FC<BannerProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <BannerLeft>
        <h1>{t("maxsulot oilalar")}</h1>
        <p>
        {t("banner_info")}
        </p>
        <BtnWrap>
          <div className="red-btn">
            <Button
              redirect="get-information"
              color="red"
              text= {`${t("maslahat_olish")}`}
            />
          </div>
          <div className="blue-btn">
            <Button redirect="order" color="primary" text={`${t("buyurtma_berish")}`} />
          </div>
        </BtnWrap>
      </BannerLeft>
      <BannerRight>
        <img src={complex} alt="" />
      </BannerRight>
    </Wrapper>
  );
};

export default Banner;
