import React, { useState } from "react";
import {
  HidePhone,
  NavbarBottom,
  NavbarBottomBetween,
  NavbarBottomEnd,
  NavbarTop,
  NavbarTopLeft,
  NavbarTopRight,
  Wrapper,
} from "./style";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { translationsRu, translationsUz } from "../../i18n/i18n";
import logo from "../../assets/images/logo.svg";
import phoneCall from "../../assets/images/phone-call.svg";
import rus from "../../assets/icons/rus.svg";
import uzb from "../../assets/icons/uzb.svg";

interface NavbarProps {}

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translationsUz },
    ru: { translation: translationsRu },
  },
  lng: "uz",
  fallbackLng: "uz",
  interpolation: { escapeValue: false },
});

const Navbar: React.FC<NavbarProps> = () => {
  const [menu, setMenu] = useState(false);

  const { t, i18n } = useTranslation();

  const handleLang = (lang: string) => {
    i18n.changeLanguage(lang);
    console.log(lang);
  };

  return (
    <Wrapper>
      <div style={{ right: menu ? 0 : "100%" }} className="media-wrapper">
        <div className="logo">
          <img src={logo} alt="" />
          <i onClick={() => setMenu(!menu)} className="fa-solid fa-xmark"></i>
        </div>
        <div className="links">
          <ul>
            <li onClick={() => setMenu(!menu)}>
              <a href="#banner">{t("asosiy")}</a>
            </li>
            <li onClick={() => setMenu(!menu)}>
              <a href="#about">{t("biz haqimizda")}</a>
            </li>
            <li onClick={() => setMenu(!menu)}>
              <a href="#comments">{t("izohlar")}</a>
            </li>
            <li onClick={() => setMenu(!menu)}>
              <a href="#certificate">{t("sertifikatlar")}</a>
            </li>
          </ul>
        </div>
        <div className="languages">
          <span onClick={() => handleLang("uz")}>
            <img src={uzb} alt="" /> UZB
          </span>
          <span onClick={() => handleLang("ru")}>
            <img src={rus} alt="" /> RUS
          </span>
        </div>

        <div className="media-footer">
          <div className="social-medias">
          <a href="https://www.facebook.com/profile.php?id=100092253319546">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://t.me/denota40_rasmiy">
            <i className="fa-brands fa-telegram"></i>
          </a>
          <a href="https://www.instagram.com/denota_40/"> 
            <i className="fa-brands fa-instagram"></i>
          </a>
          </div>
          <a href="#" className="privacy-policy">
            Privice Policy
          </a>
        </div>
      </div>
      <NavbarTop>
        <NavbarTopLeft>
          <span>
            <i className="fa-solid fa-phone"></i>
            +998 (55) 510-98-76
          </span>
          <span>
            <i className="fa-solid fa-clock"></i>
            24/7 doimiy aloqa 
          </span>
        </NavbarTopLeft>
        <NavbarTopRight>
          <span onClick={() => handleLang("uz")}>
            <img src={uzb} alt="" /> UZB
          </span>
          <span onClick={() => handleLang("ru")}>
            <img src={rus} alt="" /> RUS
          </span>
        </NavbarTopRight>
      </NavbarTop>
      <NavbarBottom>
        <button onClick={() => setMenu(!menu)} className="bar">
          <i className="fa-solid fa-bars"></i>
        </button>
        <img className="logo" src={logo} alt="" />
        <NavbarBottomBetween>
          <ul>
            <li>
              <a href="#banner">{t("asosiy")}</a>
            </li>
            <li>
              <a href="#about">{t("biz haqimizda")}</a>
            </li>
            <li>
              <a href="#comments">{t("izohlar")}</a>
            </li>
            <li>
              <a href="#certificate">{t("sertifikatlar")}</a>
            </li>
          </ul>
        </NavbarBottomBetween>
        <NavbarBottomEnd>
          <a className="phone-number" href="tel: +998 (55) 510-98-76">
          +998 (55) 510-98-76
          </a>
          <HidePhone>
            <a href="tel: +998 (55) 510-98-76">
              <img src={phoneCall} alt="" />
            </a>
          </HidePhone>
        </NavbarBottomEnd>
      </NavbarBottom>
    </Wrapper>
  );
};

export default Navbar;
