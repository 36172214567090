import React from "react";
import { useTranslation } from "react-i18next";
import Form from "./form/form";
import cls from "./style.module.scss";

interface BottomInformationProps { }

const BottomInformation: React.FC<BottomInformationProps> = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.texts}>
        <h1>DeNota 40</h1>
        <p>{t("bottom_information_message")}</p>
      </div>
      <Form />
    </div>
  );
};

export default BottomInformation;
