import React from "react";
import { useTranslation } from "react-i18next";
import Form from "./form/form";
import pharmacy from "../../assets/images/pharmacy.png";
import cls from "./style.module.scss";

interface EnterInformationProps { }

const EnterInformation: React.FC<EnterInformationProps> = () => {
  const { t } = useTranslation();

  return (
    <div id="get-information" className={cls.wrapper}>
      <div className={cls.items}>
        <Form />
        <img className={cls.pharmacy} src={pharmacy} alt="" />
      </div>
    </div>
  );
};

export default EnterInformation;
