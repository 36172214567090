import React from "react";
import { useTranslation } from "react-i18next";
import activity1 from "../../assets/images/activity1.svg";
import activity2 from "../../assets/images/activity2.svg";
import activity3 from "../../assets/images/activity3.svg";
import Item from "./item/item";
import { Wrapper } from "./style";

interface DelieversProps {}

const delievers = [
  {
    img: activity1,
    text: "Mutaxassis maslahati",
  },
  {
    img: activity2,
    text: "24/7 Xizmatingizda",
  },
  {
    img: activity3,
    text: "Bepul yetkazib berish",
  },
];

const Delievers: React.FC<DelieversProps> = () => {

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Item img={activity1} text={"delivery1"} />
      <Item img={activity2} text={"delivery2"} />
      <Item img={activity3} text={"delivery3"} />
      
      {/* {delievers.map((item) => (
        <Item img={item.img} text={item.text} />
      ))} */}
    </Wrapper>
  );
};

export default Delievers;
