import styled from "styled-components";


export const Wrapper = styled.div`

`

export const BannerLeft = styled.div`
 display: flex;
 flex-direction: column;
 width: 50%;
 padding: 0 60px;
 margin: 7% 0;

 h1 {
	font-size: 65px;
	font-weight: 700;
	color: #fff;
 }

 p {
	font-size: 25px;
	margin-top: 20px;
	font-weight: 400;
	color: #fff;
	line-height: 44px;
	width: 85%
 }

 @media (max-width: 991px) {

 	width: 100%;
 	padding: 0 20px;

	h1 {
		font-size: 2.5rem;
		width: 90%;
		margin-top: 15%;
		line-height: 50px;
		overflow: hidden;
	}

	p {
		display: none;
	}

 }
`

export const BannerRight = styled.div`

 display: none;


 @media (max-width: 991px) {
	display: block;

	img {
		width: 100%;
	}
 }

`

export const BtnWrap = styled.div`
display: flex;
align-items: center;
gap: 15px;
margin-top: 20px;


 @media (max-width: 991px) {
	.blue-btn {
		display: none;
	}
 }

`