import React from "react";
import { useTranslation } from "react-i18next";
import pharmacy from "../../assets/images/infor-image.svg";
//@ts-ignore
import file from "../../assets/denota.pdf";
import cls from "./style.module.scss";

interface PharmacyInformationProps {}

const PharmacyInformation: React.FC<PharmacyInformationProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <h1>
      DeNota 40 andro complex<br/>{t("information_title2")}
      </h1>

      <div className={cls["infor-wrapper"]}>
        <div className={cls.left}>
          <ul>
            <li>
              <span>__</span>{t("information1")}
            </li>
            <li>
              <span>__</span>{t("information2")}
            </li>
            <li>
              <span>__</span>{t("information3")}
            </li>
            <li>
              <span>__</span>{t("information4")}
            </li>
            <li>
              <span>__</span>{t("information5")}
            </li>
            <li>
              <span>__</span>{t("information6")}
            </li>
            <li>
              <span></span> <br/>
            </li>
            <li>
              <span></span><b>{t("information7")}</b>
            </li>
            <li>
              <span></span><b>{t("information8")}</b>
            </li>
          </ul>
        </div>
        <div className={cls.right}>
          <img src={pharmacy} alt="" />
        </div>
      </div>
      <a href={file} download className={cls["pharmacy-button"]}>
      {t("download_pdf")}
      </a>
    </div>
  );
};

export default PharmacyInformation;
