import { ButtonStyleProps } from './button';
import  styled  from "styled-components";



export const Button = styled.button<ButtonStyleProps>`
padding: 16px 32px;
border-radius: 10px;
border: none;
outline: none;
font-weight: 500;
font-size: 18px;
cursor: pointer;
color: #fff;
background-color: ${props => (props.variant === "primary" ? "#0273B7" : "#D65693")};

  @media (max-width: 991px) {
	 font-size: 14px;
  }
`;

