export const translationsUz = {
  //navbar
  asosiy: "Asosiy",
  "biz haqimizda": "Biz haqimizda",
  izohlar: "Fikrlar",
  sertifikatlar: "Sertifikatlar",

  //Banner (home)
  "maxsulot oilalar":
    "O‘zingizga bo‘lgan ishonchni oshirish vaqti keldi erkaklar",
  banner_info:
    "Tarkibida 14 xil vitaminlar va tabiiy elementlardan tayyorlangan DeNota 40",
  maslahat_olish: "Maslahat olish",
  buyurtma_berish: "Buyurtma berish",

  // About
  about_title:
    "Bugungi kunda biologik faol qo'shimchalar o'zbek bozorini egallagan. Shu sababdan qaysi mahsulotga ishonch bildirishni ham bilmay qoldi odam.",
  about_info:
    "🔰 Lekin 2016-yildan buyon o'z faoliyatini olib borayotgan Biostandart farmasevtika korxonasidan ishlab chiqarilgan ushbu bad mahsuloti 100% halol va sinalgan mahsulot hisoblanadi. Korxona shu paytgacha 80 dan oshiq mahsulotlarni ishlab chiqarmoqda, 200 ga yaqin hodim ish olib boradi.",

  //Activities
  activity1: "Mamnun mijozlar",
  activity2: "Faoliyat yili",
  activity3: "Umumiy sotilganlar",

  //form
  form_title: "Ma'lumotingizni kiriting",
  form_message: "Mutahassislarimiz siz bilan tez orada bog'lanadi",
  name_placeholder: "Ismingizni kiriting",
  phone_placeholder: "Raqamingizni kiriting",
  form_button: "Maslahat olish",
  form_hint: "Xavotirga o'rin yo'q, sababi ma'lumotingiz sir saqlanadi",
  comment: "Izoh",

  //bottom-information
  bottom_information_message: "Sizning salomatligingiz haqida qayg’uradi",

  //dialog
  success_dialog_message:
    "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",
  error_dialog_message: "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",

  //sertifikatlar
  sertifikat_title: "Sertifikatlar",

  //customers
  customers_title: "Bizning mamnun mijozlarimizning fikrlari",

  //deliviries
  delivery1: "Mutaxassis maslahati",
  delivery2: "24/7 Xizmatingizda",
  delivery3: "Bepul yetkazib berish",

  //information
  information_title1: "DeNota 40 andro complex",
  information_title2:
    "Tarkibida 14 xil vitaminlar va tabiiy elementlardan tayyorlangan mahsulot",
  information1: "Erkaklik quvvatini kuchaytiradi;",
  information2: "Sperma sifatini yaxshilaydi;",
  information3: "Jinsiy xohishni kuchaytiradi;",
  information4: "Jinsiy hissiyotlarning yorqinligini oshiradi;",
  information5: "Jinsiy aloqa vaqti ustidan nazoratni yaxshilaydi;",
  information6: "Testosteron darajasini oshiradi.",
  information7: "Mahsulot dori vositasi emas.",
  information8: "Biologik faol qo'shimcha hisolanadi.",
  download_pdf: "Yo’riqnomani yuklash",

  // contact
  contact_us: "Bizga qo'ng'iroq qiling",
  contact_us_info: "Biz haftada 7 kun, 24/7 ishlaymiz",
  write_us: "Bizga yozing",
  write_us_info: "Sizning muammoingizni hal qilishimiz uchun yozing",
  email: "Email: denotauzb@gmail.com",
  address_title: "Bizning manzilimiz",
  address: "Toshkent Sh. Kichik halqa yo'li, 17a",

  // errors
  name_error: "Ismingizni kiriting",
  phone_error: "Raqamingizni kiriting",
};

export const translationsRu = {
  //navbar
  asosiy: "Oсновной",
  "biz haqimizda": "O нас",
  izohlar: "Отзивы",
  sertifikatlar: "Сертификаты",

  //Banner (home)
  "maxsulot oilalar": "Подарите своей семье счастье детей с нашей продукцией",
  banner_info:
    "Denota 40 содержит 14 различных витаминов и природных элементов.",
  maslahat_olish: "Получение консультации",
  buyurtma_berish: "Заказать",

  // About
  about_title:
    "Сегодня биологически активные добавки захватили узбекский рынок. По этой причине человек даже не знал, какому продукту доверять.",
  about_info:
    "🔰 Но этот продукт, произведенный на фармацевтическом предприятии Biostandart, которое работает с 2016 года, является на 100% честным и проверенным продуктом. На данный момент предприятие выпускает более 80 наименований продукции, в нем работает около 200 сотрудников.",

  //Activities
  activity1: "Довольные клиенты",
  activity2: "Год деятельности",
  activity3: "Общие продажи",

  //form
  form_title: "Введите информацию",
  form_message: "Наши специалисты свяжутся с вами в ближайшее время",
  name_placeholder: "Введите имя",
  phone_placeholder: "Введите номер",
  form_button: "Получение консультации",
  form_hint:
    "Нет места для беспокойства, потому что ваша информация остается конфиденциальной",
  comment: "Примечание",

  //bottom-information
  bottom_information_message: "Заботится о вашем здоровье",

  //dialog
  success_dialog_message:
    "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",
  error_dialog_message: "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",

  //sertifikatlar
  sertifikat_title: "Сертификаты",

  //customers
  customers_title: "Отзывы наших довольных клиентов",

  //deliviries
  delivery1: "Совет эксперта",
  delivery2: "К вашим услугам 24/7",
  delivery3: "Бесплатная доставка",

  //information
  information_title1: "DeNota 40 andro complex",
  information_title2:
    "Продукт, состоящий из 14 различных витаминов и природных элементов",
  information1: "Повышает мужскую силу;",
  information2: "Улучшает качество спермы;",
  information3: "Повышает сексуальное желание;",
  information4: "Повышает яркость сексуальных ощущений;",
  information5: "Улучшает контроль над временем секса;",
  information6: "Повышает уровень тестостерона.",
  information7: "Продукт не является лекарственным средством.",
  information8: "Является биологически активной добавкой.",
  download_pdf: "Скачать инструкцию",

  // contact
  contact_us: "Позвоните нам",
  contact_us_info: "Мы работаем 24/7, 7 дней в неделю",
  write_us: "Напишите нам",
  write_us_info: "Напишите нам, чтобы решить вашу проблему",
  email: "Email: denotauzb@gmail.com",
  address_title: "Наш адрес",
  address: "Г. Ташкент, Малая колесовая дорога, 17а",

  // errors
  name_error: "Введите Ваше имя",
  phone_error: "Введите свой номер",
};
