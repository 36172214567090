import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import cls from "./style.module.scss";
import Customer from "./item/customer";

interface CustomersProps {}

const Customers: React.FC<CustomersProps> = () => {
  const { t } = useTranslation();
  return (
    <div id="comments" className={cls.wrapper}>
      <h1>{t("customers_title")}</h1>
      <div className={cls.swiper}>
        <Swiper
          className={cls.items}
          spaceBetween={150}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={cls["swiper-media"]}>
        <Swiper
          className={cls.items}
          spaceBetween={20}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
          <SwiperSlide>
            <Customer />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Customers;
