import React from "react";
import { useTranslation } from "react-i18next";
import Item from "./item/item";
import first from "../../assets/images/01.png";
import second from "../../assets/images/02.png";
import third from "../../assets/images/03.png";
import cls from "./style.module.scss";

interface ActivitiesProps {}

const Activities: React.FC<ActivitiesProps> = () => {
  const { t } = useTranslation();

  const items = [
    {
      img: first,
      text: t("activity1"),
      counter: 1000,
    },
    {
      img: second,
      text: t("activity2"),
      counter: 7,
    },
    {
      img: third,
      text: t("activity3"),
      counter: 500,
    },
  ];

  return (
    <div className={cls.wrapper}>
      {items.map((item) => (
        <div>
          <Item {...item} />
        </div>
      ))}
    </div>
  );
};

export default Activities;
