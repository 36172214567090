import React from "react";
import { useTranslation } from "react-i18next";
import first from "../../assets/images/first.png";
import second from "../../assets/images/second.png";
import third from "../../assets/images/third.png";
import cls from "./style.module.scss";

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  const { t } = useTranslation();
  return (
    <div id="about" className={cls.wrapper}>
      <div className={cls.left}>
        <div className={cls["img-top"]}>
          <img className={cls.third} src={third} alt="" />
          <img className={cls.second} src={second} alt="" />
        </div>
        <img className={cls.pharmacy} src={first} alt="" />
      </div>
      <div className={cls.right}>
        <div className={cls.heading}>
          <h5>
          {t("about_title")}
          </h5>
        </div>
        <div className={cls["longt-text"]}>
          <p>
          {t("about_info")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
