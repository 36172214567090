import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form"
import cls from "./style.module.scss";
import { useTranslation } from "react-i18next";

interface FormProps { }


const Form: React.FC<FormProps> = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm()


  const sendMessage = async (data: any) => {
    try {
      const texts = `Ism:${data.name} Raqam:${data.phoneNumber} 📞 #sayt`;
      await axios.get(
        `https://api.telegram.org/bot6001338490:AAHqkDtsfL2A3do21Dj2c2R3Cj5Fw-PaprE/sendMessage?chat_id=-1001536066859&text=${texts}`
      );
      Swal.fire(
        "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",
        "",
        "success"
      );
      reset();
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Xatolik... ",
        text: "Internet ulanganligini tekshirib, qayta urinib ko'ring.",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(sendMessage)} className={cls.wrapper}>
      <div className={cls.head}>
        <h4>{t("form_title")}</h4>
        <span>{t("form_message")}</span>
      </div>
      <div className={cls.inputs}>
        <input
          {...register("name", { required: true })}
          type="text"
          placeholder={`${t("name_placeholder")}`}
        />
        {errors.name && <p className={cls.error}>{t("name_error")}</p>}
        <input
          {...register("phoneNumber", { required: true, })}
          type="number"
          placeholder={`${t("phone_placeholder")}`}
        />
        {errors.phoneNumber && <p className={cls.error}>{t("phone_error")}</p>}
      </div>
      <div className={cls.bottom}>
        <button type="submit">{t("form_button")}</button>
        <span>{t("form_hint")}</span>
      </div>
    </form>
  );
};

export default Form;
