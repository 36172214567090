import React from "react";
import comma from "../../../assets/icons/comma.svg";
import cls from "./style.module.scss";

interface CustomerProps {}

const Customer: React.FC<CustomerProps> = () => {
  return (
    <div className={cls.wrapper}>
      <div>
        <div className={cls.images}>
          <img
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt=""
          />
          <img className={cls.comma} src={comma} alt="" />
          <h2>Esther Howard</h2>
        </div>
        <p style={{ marginTop: 40, fontSize: 22, lineHeight: 1.3 }}>
          Lorem ipsum dolor sit amet consectetur. Nibh quisque scelerisque
          fringilla montes morbi.{" "}
        </p>
      </div>
    </div>
  );
};

export default Customer;
