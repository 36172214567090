import React, { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import certificate1 from "../../assets/images/certificate-1.jpg";
import certificate2 from "../../assets/images/certificate-2.jpg";
import certificate3 from "../../assets/images/certificate-3.jpg";
import certificate4 from "../../assets/images/certificate-4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import cls from "./style.module.scss";

interface CertificatesProps {}

const Certificates: React.FC<CertificatesProps> = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });
  const showModal = () => {
    setIsModalOpen({ ...isModalOpen, first: true });
  };
  const handleOk = () => {
    setIsModalOpen({ ...isModalOpen, first: false });
  };
  const handleCancel = () => {
    setIsModalOpen({ ...isModalOpen, first: false });
  };

  const showModalSecond = () => {
    setIsModalOpen({ ...isModalOpen, second: true });
  };
  const handleOkSecond = () => {
    setIsModalOpen({ ...isModalOpen, second: false });
  };
  const handleCancelSecond = () => {
    setIsModalOpen({ ...isModalOpen, second: false });
  };

  const showModalThird = () => {
    setIsModalOpen({ ...isModalOpen, third: true });
  };
  const handleOkThird = () => {
    setIsModalOpen({ ...isModalOpen, third: false });
  };
  const handleCancelThird = () => {
    setIsModalOpen({ ...isModalOpen, third: false });
  };

  const showModalFourth = () => {
    setIsModalOpen({ ...isModalOpen, fourth: true });
  };
  const handleOkFourth = () => {
    setIsModalOpen({ ...isModalOpen, fourth: false });
  };
  const handleCancelFourth = () => {
    setIsModalOpen({ ...isModalOpen, fourth: false });
  };

  return (
    <div id="certificate" className={cls.wrapper}>
      <h1>{t("sertifikat_title")}</h1>
      <div className={cls.images}>
        <img
          onClick={showModal}
          className={cls.img1}
          src={certificate1}
          alt=""
        />
        <img
          onClick={showModalSecond}
          className={cls.img2}
          src={certificate2}
          alt=""
        />
        <img
          onClick={showModalThird}
          className={cls.img3}
          src={certificate3}
          alt=""
        />
        <img
          onClick={showModalFourth}
          className={cls.img4}
          src={certificate4}
          alt=""
        />
      </div>

      <div className={cls["swiper-media"]}>
        <Swiper
          className={cls.items}
          spaceBetween={5}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          <SwiperSlide>
            <img className={cls.img1} src={certificate1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={cls.img2} src={certificate2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={cls.img3} src={certificate3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={cls.img4} src={certificate4} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      <Modal
        className={cls.modal}
        open={isModalOpen.first}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div>
          <img className={cls.modalImg} src={certificate1} alt="" />
        </div>
      </Modal>
      <Modal
        className={cls.modal}
        open={isModalOpen.second}
        onOk={handleOkSecond}
        onCancel={handleCancelSecond}>
        <div>
          <img className={cls.modalImg} src={certificate2} alt="" />
        </div>
      </Modal>
      <Modal
        className={cls.modal}
        open={isModalOpen.third}
        onOk={handleOkThird}
        onCancel={handleCancelThird}>
        <div>
          <img className={cls.modalImg} src={certificate3} alt="" />
        </div>
      </Modal>
      <Modal
        className={cls.modal}
        open={isModalOpen.fourth}
        onOk={handleOkFourth}
        onCancel={handleCancelFourth}>
        <div>
          <img className={cls.modalImg} src={certificate4} alt="" />
        </div>
      </Modal>
    </div>
  );
};
export default Certificates;
