import  styled  from "styled-components";



export const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 120px 140px;


@media (max-width: 991px) {
	padding: 50px 140px;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	gap: 80px;
 }


`