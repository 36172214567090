import React from "react";
import {
  About,
  Activities,
  Banner,
  BottomInformation,
  Certificates,
  Customers,
  Delievers,
  EnterInformation,
  Footer,
  Form,
  Navbar,
  PharmacyInfor,
} from "./components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <div className="wrapper">
      <div id="banner" className="banner-wrapper">
        <Navbar />
        <Banner />
      </div>
      <AnimationOnScroll animateIn="animate__fadeIn" duration={1}>
        <Delievers />
      </AnimationOnScroll>

      <div className="bottom-wrapper">
        <div className="bottom-wrap">
          <AnimationOnScroll animateIn="animate__fadeIn" duration={1}>
            <About />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <EnterInformation />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <Activities />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <PharmacyInfor />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <Customers />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <BottomInformation />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <Certificates />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <Form />
          </AnimationOnScroll>
          <AnimationOnScroll duration={1} animateIn="animate__fadeIn">
            <Footer />
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default App;
