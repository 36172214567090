import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form"
import cls from "./style.module.scss";

interface FormProps { }

const Form: React.FC<FormProps> = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const [inputValues, setInputValues] = useState({
    comment: "",
  });




  const sendMessage = async (data: any) => {
    const { comment } = inputValues;
    try {
      const texts = `Ism:${data.name} Raqam:${data.phoneNumber} Izoh(optional):${comment}  #sayt`;

      await axios.get(
        `https://api.telegram.org/bot6001338490:AAHqkDtsfL2A3do21Dj2c2R3Cj5Fw-PaprE/sendMessage?chat_id=-1001536066859&text=${texts}`
      );
      Swal.fire(
        "Mutahassislarimiz siz bilan tez orada bog'lanishadi.",
        "",
        "success"
      );
      reset();
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Xatolik...",
        text: "Internet ulanganligini tekshirib, qayta urinib ko'ring.",
      });
    }

  };

  return (
    <div id="order" className={cls.wrapper}>
      <div className={cls.left}>
        <div>
          <div className={cls.item}>
            <div className={cls.text}>
              <div className={cls.top}>
                <i className="fa-solid fa-phone"></i>
                <h3>{t("contact_us")}</h3>
              </div>
              <p>{t("contact_us_info")}</p>
              <a href="tel: +998 55 510-98-76">+998 (55) 510-98-76</a>
            </div>
          </div>
        </div>
        <div>
          <div className={cls.item}>
            <div className={cls.text}>
              <div className={cls.top}>
                <i className="fa-solid fa-envelope"></i>
                <h3>{t("write_us")}</h3>
              </div>
              <p>{t("write_us_info")}</p>
              <a href="denotauzb@gmail.com">{t("email")}</a>
            </div>
          </div>
        </div>
        <div>
          <div className={cls.item}>
            <div className={cls.text}>
              <div className={cls.top}>
                <i className="fa-solid fa-location-dot"></i>
                <h3>{t("address_title")}</h3>
              </div>
              <p>{t("address")}</p>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(sendMessage)} className={cls.right}>
        <div className={cls.input}>
          <i className="fa-solid fa-user"></i>
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder={`${t("name_placeholder")}`}
            id=""
          />
          {errors.name && <p className={cls.error}>{t("name_error")}</p>}
        </div>
        <div className={cls.input}>
          <i className="fa-solid fa-phone"></i>
          <input
            {...register("phoneNumber", { required: true, })}
            type="text"
            placeholder={`${t("phone_placeholder")}`}
            id=""
          />
          {errors.phoneNumber && <p className={cls.error}>{t("phone_error")}</p>}
        </div>
        <div className={cls.textarea}>
          <i className="fa-solid fa-pen-to-square"></i>
          <textarea
            value={inputValues.comment}
            onChange={(e: any) =>
              setInputValues({ ...inputValues, comment: e.target.value })
            }
            placeholder={`${t("comment")}`}
            id=""
            rows={14}
            cols={50}></textarea>
        </div>
        <div className={cls.button}>
          <button type="submit">
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;


