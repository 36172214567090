import React from "react";
import { Wrapper } from "./style";
import present from "../../../assets/images/present.svg";
import { useTranslation } from "react-i18next";

interface ItemProps {
  img: any;
  text: string;
}

const Item: React.FC<ItemProps> = ({ img, text }) => {

  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={img} alt="" />
      <p>{t(text)}</p>
    </Wrapper>
  );
};

export default Item;
