import React, { useEffect, useState } from "react";
import cls from "./style.module.scss";

interface AnimatedNumberProps {
  counter: number;
  img: any;
  text: string;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({
  counter,
  img,
  text,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const topOffset = window.pageYOffset + window.innerHeight;
      const elementOffset =
        document.getElementById("animatedNumber")!.offsetTop;

      if (topOffset > elementOffset) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isVisible && count < counter) {
      const timeout = setTimeout(() => {
        setCount((prevCount) => prevCount + 1);
      }, 4);
      return () => clearTimeout(timeout);
    }
  }, [isVisible, count, counter, window.innerHeight]);

  return (
    <div id="animatedNumber" className={`number ${isVisible ? "visible" : ""}`}>
      <div className={cls.wrapper}>
        <img src={img} alt="" />
        <div className={cls.texts}>
          <h1>+{count}</h1>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedNumber;
