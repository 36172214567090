import styled from "styled-components"


export const Wrapper = styled.div`
text-align: center;

img{ 
	padding: 10px;
 }
p {
	border-top: 1px solid #D65693;
	padding: 10px 0;
	width: 240px;
	font-size: 22px;
	font-weight: 500;
}


  @media (max-width: 991px) {
	p {
		width: 280px;
	}
  }

`