import styled from "styled-components";


export const Wrapper = styled.div`
 display: flex;
 flex-direction: column;
 position: relative;


 .media-wrapper {
	width: 100%;
	background: rgb(255,255,255);
	background: linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(0,0,0,0.4990589985994398) 80%, rgba(0,0,0,0.4990589985994398) 81%);
	position: fixed;
	z-index: 100;
	height: 100vh;
	transition: all .2s linear;

	.logo {
		padding: 46.5px 20px;
		border-bottom: 1px solid #E9EAEA;
		display: flex;
		align-items: center;
		width: 69%;
		justify-content: space-between;
		overflow: hidden;


		i {
			font-size: 24px;
			cursor: pointer;
		}
	}
	

	.links {
		padding: 40px 20px;
		ul {
			display: flex;
			flex-direction: column;
			gap: 70px;

			li {
				list-style: none;

				a {
					text-decoration: none;
					font-size: 24px;
					color: #000;
					font-weight: 500;

				}
			}
		}
	}

	.languages {
		padding: 20px;
		display: flex;
		align-items: center;
		gap: 20px;

		span {
			display: flex;
			align-items: center;
			gap: 15px;
			cursor: pointer;
			img {
				height: 20px;
			}
		}
	}

	.media-footer {
		padding: 20px;
		margin-top: 80px;
		display: flex;
		flex-direction: column;
		gap: 15px;

		.social-medias {
			display: flex;
			align-items: center;
			gap: 20px;

			a {
				color: #000;
				font-size: 30px;
			}
		}

		.privacy-policy {
			color: #000;
		}
	}
 }

`;

export const NavbarTop = styled.div`
height: 40px;
background-color: #D65693;
padding: 0 60px;
display: flex;
justify-content: space-between;
align-items: center;


@media (max-width: 991px) {
	display: none;
}

`;


export const NavbarTopLeft = styled.div`
 display: flex;
 align-items: center;
 gap: 25px;
 padding: 10px;


 span {
	display: flex;
	gap: 10px;
	color: #fff;
	font-size: 14px;
	font-weight: 300;
 }
  
`

export const NavbarTopRight = styled.div`
 color: #fff;
 display: flex;
 gap: 15px;
 
 span {
	cursor: pointer;
	font-weight: 300;
 }
`

export const NavbarBottom = styled.div`
padding: 25px 70px;
display: flex;
align-items: center;
justify-content: space-between;
background: rgba(255, 255, 255, 0.4);
backdrop-filter: blur(7.5px);


.bar {
	display: none;
}


@media (max-width: 991px) {
	padding: 44px 20px;
	position: relative;

	.logo {
		position: absolute;
		left: 35%;
	}

	.bar {
		display: block;
		background: transparent;
		border: none;
		font-size: 24px;
		cursor: pointer;
		color: #D65693;
		line-height: none
	}
}

`

export const NavbarBottomBetween = styled.div`

ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 25px;

li {
	list-style: none;
	font-size: 16px;

	a {
		text-decoration: none;
		color: #000;
		font-weight: 500;
	}


  }

  @media (max-width: 991px) {
	display: none;
  }

}

`

export const NavbarBottomEnd = styled.div`

a {
	text-decoration: none;
	color: #0273B7;
	font-weight: 500;
}



	@media (max-width: 991px) {
		.phone-number {
			display: none;
		}
	}

`

export const HidePhone = styled.div`

display: none;


  @media (max-width: 991px) {
	display: block;
  }


`