import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/footer-logo.svg";
import cls from "./style.module.scss";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={cls.wrapper}>
        <img src={logo} alt="" />
        <p>{t("address")}</p>
        <div className={cls.icons}> 
          <a href="https://www.facebook.com/profile.php?id=100092253319546" target="blank">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://t.me/denota40_rasmiy" target="blank">
            <i className="fa-brands fa-telegram"></i>
          </a>
          <a href="https://www.instagram.com/denota_40/" target="blank"> 
            <i className="fa-brands fa-instagram"></i>
          </a>
        </div>
        <a href="#">Privicy Policy</a>
      </div>
      <div className={cls["footer-bottom"]}>
        <span>© 2023 YuksakSoft. All Rights Reserved.</span>
      </div>
    </>
  );
};

export default Footer;
