import React from "react";
import { Button as CustomButton } from "./style";

interface ButtonProps {
  text: string;
  color: string;
  redirect?: string;
}

export type ButtonStyleProps = {
  variant: string;
};

const Button: React.FC<ButtonProps> = ({ text, color, redirect }) => {
  return (
    <a href={`#${redirect}`}>
      <CustomButton variant={color}>{text}</CustomButton>
    </a>
  );
};

export default Button;
